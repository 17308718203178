export const comments = {
  ja: {
    score: {
      up: `生活習慣スコアは6月より%d点上がりました。暑さに負けず、順調ですね！<br>
      疲れが抜けにくい、食欲が湧かなくなるという夏バテの症状が出やすくなります。<br>
      4つのことに気を付けて、夏バテを防ぎましょう。<br>
      ①こまめな水分補給<br>
      ②温度差や体の冷やし過ぎに注意<br>
      ③睡眠をしっかりとる<br>
      ④1日3食バランスよく食べる`,
      down: `生活習慣スコアは6月より%d点下がりました。できることから気を付けてみましょう。<br>
      疲れが抜けにくい、食欲が湧かなくなるという夏バテの症状が出やすくなります。<br>
      4つのことに気を付けて、夏バテを防ぎましょう。<br>
      ①こまめな水分補給<br>
      ②温度差や体の冷やし過ぎに注意<br>
      ③睡眠をしっかりとる<br>
      ④1日3食バランスよく食べる`,
      equal100: `生活習慣スコアは6月に引き続き<span class="good">満点</span>です。暑さに負けず、大変素晴らしいです！<br>
      疲れが抜けにくい、食欲が湧かなくなるという夏バテの症状が出やすくなります。<br>
      4つのことに気を付けて、夏バテを防ぎましょう。<br>
      ①こまめな水分補給<br>
      ②温度差や体の冷やし過ぎに注意<br>
      ③睡眠をしっかりとる<br>
      ④1日3食バランスよく食べる`,
      equal: `生活習慣スコアは6月と同じ点数です。分類別評価の項目に変化があったか振り返ってみましょう。<br>
      疲れが抜けにくい、食欲が湧かなくなるという夏バテの症状が出やすくなります。<br>
      4つのことに気を付けて、夏バテを防ぎましょう。<br>
      ①こまめな水分補給<br>
      ②温度差や体の冷やし過ぎに注意<br>
      ③睡眠をしっかりとる<br>
      ④1日3食バランスよく食べる`,
    },
    steps: {
      up: `歩数スコアは6月より%d点上がりました。<br>
      暑くなっても歩数が増やせています。さすがです！<b>Walk チーム対抗戦 2022 2nd</b>のエントリ―が8月23日から開始！お楽しみに♪`,
      down: `歩数スコアは6月より%d点下がりました。
      <b>Walk チーム対抗戦 2022 2nd</b>のエントリ―が8月23日から開始！お楽しみに♪`,
      equal: `歩数スコアは6月と同じです。
      <b>Walk チーム対抗戦 2022 2nd</b>のエントリ―が8月23日から開始！お楽しみに♪`,
    },
    exercise: {
      up: `運動のスコアは6月より%d点上がりました。<br>
      ストレッチは身体のだるさや冷えむくみ対策にもつながります。
      早朝や就寝前など涼しい時間の方が気持ちよく身体を動かせますよ。`,
      down: `運動のスコアは6月より%d点下がりました。<br>
      ストレッチは身体のだるさや冷えむくみ対策にもつながります。
      早朝や就寝前など涼しい時間の方が気持ちよく身体を動かせますよ。`,
      equal: `運動のスコアは6月と同じです。<br>
      ストレッチは身体のだるさや冷えむくみ対策にもつながります。
      早朝や就寝前など涼しい時間の方が気持ちよく身体を動かせますよ。`,
    },
    meal: {
      up: `食事のスコアは6月より%d点上がりました。<br>
      「疲れやすい」「だるい」…こんな症状を感じていたらビタミン不足かもしれません。<a href="https://go.andwell.jp/library/482434dd-5e36-11ec-9728-06d158a16ffc">コラム</a>であなたに足りないビタミンをチェック！`,
      down: `食事のスコアは6月より%d点下がりました。<br>
      「疲れやすい」「だるい」…こんな症状を感じていたらビタミン不足かもしれません。<a href="https://go.andwell.jp/library/482434dd-5e36-11ec-9728-06d158a16ffc">コラム</a>であなたに足りないビタミンをチェック！`,
      equal: `食事のスコアは6月と同じです。<br>
      「疲れやすい」「だるい」…こんな症状を感じていたらビタミン不足かもしれません。<a href="https://go.andwell.jp/library/482434dd-5e36-11ec-9728-06d158a16ffc">コラム</a>であなたに足りないビタミンをチェック！`,
    },
    drinking: {
      up: `飲酒のスコアは6月より%d点上がりました。<br>
      お酒を飲んだときはビタミンB1が不足しがち。冷奴や枝豆を食べて、ビタミンB1をとりましょう。豚肉やレバーにも多く含まれています。`,
      down: `飲酒のスコアは6月より%d点下がりました。<br>
      お酒を飲んだときはビタミンB1が不足しがち。冷奴や枝豆を食べて、ビタミンB1をとりましょう。豚肉やレバーにも多く含まれています。`,
      equal: `飲酒のスコアは6月と同じです。<br>
      お酒を飲んだときはビタミンB1が不足しがち。冷奴や枝豆を食べて、ビタミンB1をとりましょう。豚肉やレバーにも多く含まれています。`,
    },
    sleep: {
      up: `睡眠のスコアは6月より%d点上がりました。<br>
      <a href="https://go.andwell.jp/library/154e518b-5e33-11ec-9728-06d158a16ffc">コラム</a>で、真夏でもしっかりと寝て朝すっきりと目覚められるちょっとしたコツを３つご紹介！なかなか寝付けない方はまずは1つお試しください！`,
      down: `睡眠のスコアは6月より%d点下がりました。<br>
      <a href="https://go.andwell.jp/library/154e518b-5e33-11ec-9728-06d158a16ffc">コラム</a>で、真夏でもしっかりと寝て朝すっきりと目覚められるちょっとしたコツを３つご紹介！なかなか寝付けない方はまずは1つお試しください！`,
      equal: `睡眠のスコアは6月と同じです。<br>
      <a href="https://go.andwell.jp/library/154e518b-5e33-11ec-9728-06d158a16ffc">コラム</a>で、真夏でもしっかりと寝て朝すっきりと目覚められるちょっとしたコツを３つご紹介！なかなか寝付けない方はまずは1つお試しください！`,
    },
    stress: {
      up: `ストレスのスコアは6月より%d点上がりました。<br>
      急激な温度変化は心身ともに負担になります。人が室内で過ごせる快適な温度は、一般的に<b>夏場は25～28度</b>と言われています。`,
      down: `ストレスのスコアは6月より%d点下がりました。<br>
      急激な温度変化は心身ともに負担になります。人が室内で過ごせる快適な温度は、一般的に<b>夏場は25～28度</b>と言われています。`,
      equal: `ストレスのスコアは6月と同じです。<br>
      急激な温度変化は心身ともに負担になります。人が室内で過ごせる快適な温度は、一般的に<b>夏場は25～28度</b>と言われています。`,
    },
  },
  en: {
    score: {
      up: `Your lifestyle score has gone up %d pts since June. You're doing well despite the heat!<br>
      It's easy to fall into symptoms of summer fatigue, such as constant tiredness or lack of appetite.<br>
      Keep these four things in mind to prevent summer fatigue.<br>
      (1) Stay hydrated<br>
      (2) Pay attention to changes in temperature and be careful not to cool your body too much<br>
      (3) Get plenty of sleep<br>
      (4) Eat three balanced meals each day`,
      down: `Your lifestyle score has gone down %d pts since June. Start by improving things you can control.<br>
      It's easy to fall into symptoms of summer fatigue, such as constant tiredness or lack of appetite.<br>
      Keep these four things in mind to prevent summer fatigue.<br>
      (1) Stay hydrated<br>
      (2) Pay attention to changes in temperature and be careful not to cool your body too much<br>
      (3) Get plenty of sleep<br>
      (4) Eat three balanced meals each day`,
      equal100: `Your lifestyle score is the <span class="good">maximum</span> again, the same as June. You're doing excellent despite the heat!<br>
      It's easy to fall into symptoms of summer fatigue, such as constant tiredness or lack of appetite.<br>
      Keep these four things in mind to prevent summer fatigue.<br>
      (1) Stay hydrated<br>
      (2) Pay attention to changes in temperature and be careful not to cool your body too much<br>
      (3) Get plenty of sleep<br>
      (4) Eat three balanced meals each day`,
      equal: `Your lifestyle score is the same as June. Use the categorized ratings to see what items have changed.<br>
      It's easy to fall into symptoms of summer fatigue, such as constant tiredness or lack of appetite.<br>
      Keep these four things in mind to prevent summer fatigue.<br>
      (1) Stay hydrated<br>
      (2) Pay attention to changes in temperature and be careful not to cool your body too much<br>
      (3) Get plenty of sleep<br>
      (4) Eat three balanced meals each day`,
    },
    steps: {
      up: `Your steps score has gone up %d pts since June.<br>
      You're getting more steps despite the heat. Good job! The entry period for the <b>2nd Walk Team Competition 2022</b> will start on August 23! Get excited!`,
      down: `Your steps score has gone down %d pts since June.<br>
      Pay attention to your steps every day. The entry period for the <b>2nd Walk Team Competition 2022</b> will start on August 23! Get excited!`,
      equal: `Your steps score is the same as June.<br>
      Pay attention to your steps every day. The entry period for the <b>2nd Walk Team Competition 2022</b> will start on August 23! Get excited!`,
    },
    exercise: {
      up: `Your exercise score has gone up %d pts since June.<br>
      Stretching also helps prevent the body from getting sluggish or swelling from the cold. <br>
      You can move your body more comfortably when it's cool, such as early in the morning or before you go to bed.`,
      down: `Your exercise score has gone down %d pts since June.<br>
      Stretching also helps prevent the body from getting sluggish or swelling from the cold. <br>
      You can move your body more comfortably when it's cool, such as early in the morning or before you go to bed.`,
      equal: `Your exercise score is the same as June.<br>
      Stretching also helps prevent the body from getting sluggish or swelling from the cold. <br>
      You can move your body more comfortably when it's cool, such as early in the morning or before you go to bed.`,
    },
    meal: {
      up: `Your food score has gone up %d pts since June.<br>
      If you get tired easily, feel sluggish, or have similar symptoms, you might be lacking vitamins. Check what vitamins you're lacking in the <a href="https://go.andwell.jp/library/482434dd-5e36-11ec-9728-06d158a16ffc">column!</a>`,
      down: `Your food score has gone down %d pts since June.<br>
      If you get tired easily, feel sluggish, or have similar symptoms, you might be lacking vitamins. Check what vitamins you're lacking in the <a href="https://go.andwell.jp/library/482434dd-5e36-11ec-9728-06d158a16ffc">column!</a>`,
      equal: `Your food score is the same as June.<br>
      If you get tired easily, feel sluggish, or have similar symptoms, you might be lacking vitamins. Check what vitamins you're lacking in the <a href="https://go.andwell.jp/library/482434dd-5e36-11ec-9728-06d158a16ffc">column!</a>`,
    },
    drinking: {
      up: `Your alcohol score has gone up %d pts since June.<br>
      You tend to lack vitamin B1 when you drink alcohol. Get more vitamin B1 by eating cold tofu or edamame. There's a lot of it in pork and liver, too.`,
      down: `Your alcohol score has gone down %d pts since June.<br>
      You tend to lack vitamin B1 when you drink alcohol. Get more vitamin B1 by eating cold tofu or edamame. There's a lot of it in pork and liver, too.`,
      equal: `Your alcohol score is the same as June.<br>
      You tend to lack vitamin B1 when you drink alcohol. Get more vitamin B1 by eating cold tofu or edamame. There's a lot of it in pork and liver, too.`,
    },
    sleep: {
      up: `Your sleep score has gone up %d pts since June.<br>
      Here are three tips from the <a href="https://go.andwell.jp/library/154e518b-5e33-11ec-9728-06d158a16ffc">column</a> to help you sleep well and wake up in the morning refreshed even in the midsummer heat! If you have trouble falling asleep, try one of these!`,
      down: `Your sleep score has gone down %d pts since June.<br>
      Here are three tips from the <a href="https://go.andwell.jp/library/154e518b-5e33-11ec-9728-06d158a16ffc">column</a> to help you sleep well and wake up in the morning refreshed even in the midsummer heat! If you have trouble falling asleep, try one of these!`,
      equal: `Your sleep score is the same as June.<br>
      Here are three tips from the <a href="https://go.andwell.jp/library/154e518b-5e33-11ec-9728-06d158a16ffc">column</a> to help you sleep well and wake up in the morning refreshed even in the midsummer heat! If you have trouble falling asleep, try one of these!`,
    },
    stress: {
      up: `Your stress score has gone up %d pts since June.<br>
      Sudden changes in temperature put stress on the body and mind. People are typically comfortable with a room temperature of <b>about 25 to 28 degrees in the summer</b>.`,
      down: `Your stress score has gone down %d pts since June.<br>
      Sudden changes in temperature put stress on the body and mind. People are typically comfortable with a room temperature of <b>about 25 to 28 degrees in the summer</b>.`,
      equal: `Your stress score is the same as June.<br>
      Sudden changes in temperature put stress on the body and mind. People are typically comfortable with a room temperature of <b>about 25 to 28 degrees in the summer</b>.`,
    },
  },
}
